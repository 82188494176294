import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import marked from 'marked';

import Grid from '../../../components/grid/Grid';
import Card from '../../../components/card/Card';

import {
    Input,
    Textarea,
    Select,
    Checkbox,
    Button,
    Form
} from "../../../components/form";

import useVacancy from "../../../hooks/useVacancy";
import useVacancies from '../../../hooks/useVacancies';
import useVacancyApply from '../../../hooks/useVacancyApply';

import { UploadIcon } from '../../../components/icons';

import classes from './Vacancy.module.css';

const Vacancy = () => {
    const { id } = useParams();
    const { fetchVacancies } = useVacancies();
    const { applyVacancy, loading, error, success } = useVacancyApply();

    const [ inputs, setInput ] = useState({ name: '', email: '', phone: '', location_id: '', file: null });

    const vacancy = useVacancy(id);

    const { locations=[] } = vacancy || {};

    useEffect(() => {
        if (vacancy === undefined) {
            fetchVacancies();
        }
    }, [ vacancy, fetchVacancies]);

    const onChange = ({ target }) => {
        setInput({
            ...inputs,
            [target.name]: target.value
        });
    }

    const onFileChange = ({ target }) => {

        if (target.files) {
            setInput({
                ...inputs,
                file: target.files[0]
            });
        }

    }

    const onSubmit = async (valid, resetForm) => {
        if (valid) {

            const result = await applyVacancy({
                vacancy_id: id,
                ...inputs
            });

            if (result) {
                setInput({ name: '', email: '', phone: '', location_id: '', file: null  });
                resetForm();
            }
        }

    }

    return (
        <Grid container>

            {
                vacancy
                &&
                <>
                <Grid sx={12} className={classes.grid}>
                    <Card className={classes.card}>
                        <h2>{ vacancy.title }</h2>
            
                        <div
                            className={classes.text}
                            dangerouslySetInnerHTML={{
                                __html: marked(vacancy.description, { headerIds: false })
                            }}
                        />
                    </Card>
                </Grid>
                <Grid sx={12} className={classes.grid}>
                    <Card className={classes.card}>
                        <div className={classes.formContainer}>
                            <Form onSubmit={onSubmit} className={classes.form}>
                                <h2>Solliciteren?</h2>

                                {
                                    error && <Grid xs={12} className={classes.form_field}><div className={classes.error}>Formulier kon niet worden verstuurd. Probeer het later nogmaals.</div></Grid>
                                }
                                {
                                    success && <Grid xs={12} className={classes.form_field}><div className={classes.success}>Formulier succesvol verstuurd!</div></Grid>
                                }

                                <Select name="location_id" label="Vestiging" onChange={onChange} value={inputs.location_id} required>
                                    <option value="">Selecteer een vestiging</option>
                                    {
                                        locations.map(({ id, name }) => {
                                            return <option key={name} value={id}>{name}</option>
                                        })
                                    }
                                </Select>

                                <Input name="name" label="Voor- en achternaam" onChange={onChange} value={inputs.name} required />
                                <Input type="email" name="email" label="E-mailadres" placeholder="voorbeeld@email.com" onChange={onChange} value={inputs.email} required />
                                <Input type="tel" name="phone" label="Telefoonnummer" onChange={onChange} value={inputs.phone} />
                                <div>
                                    <label htmlFor="file" className={classes.uploadLabel}><UploadIcon />  { inputs.file === null ? 'UPLOAD jouw beknopte c.v.' : `UPLOAD: ${inputs?.file?.name}`}</label>
                                    <input id="file" type="file" className={classes.uploadField} onChange={onFileChange} />
                                </div>
                                <Button variant="primary" className={classes.send} loading={loading}>SOLLICiTEER</Button>
                            </Form>
                        </div>
                    </Card>
                </Grid>
                </>
            }

        </Grid>
    )
}

export default Vacancy;